import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import cn from 'classnames';
// Api
import apolloStreamClient from 'api/apolloStreamClient';
import {
  PlayButtonClicked,
  PlayButtonClickedVariables,
} from 'api/agora/types/PlayButtonClicked';
import { PLAY_BUTTON_CLICKED } from 'api/agora/mutations';
// Types
import {
  GetStream_stream,
  GetStream_stream_streamRecords,
} from 'api/streams/types/GetStream';
// Helpers
import { computeDuration } from 'helpers/time';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import VideoPlayer from 'ui/VideoPlayer/VideoPlayer';
import Image from 'ui/Image/Image';
// Icons
import PlayVideoIcon from '../../../../../../public/images/watch-stream/play-video-player.svg';
import PauseVideoIcon from '../../../../../../public/images/watch-stream/pause-video-player.svg';
// Styles
import styles from './StreamVideoPlayer.module.scss';

type StreamVideoPlayerProps = {
  stream: GetStream_stream;
  refetchStream: () => void;
};

const StreamVideoPlayer = ({
  stream,
  refetchStream,
}: StreamVideoPlayerProps): JSX.Element | null => {
  const [recordVideoUrl, setRecordVideoUrl] = useState<string>('');
  const rootRef = useRef<HTMLDivElement | null>(null);
  const firstRecordUrl = stream?.streamRecords?.[0]?.videoURL || '';
  const recordsList = stream?.streamRecords || [];
  const videoPlayerUrl = recordVideoUrl || firstRecordUrl;

  const [playButtonClicked] = useMutation<
    PlayButtonClicked,
    PlayButtonClickedVariables
  >(PLAY_BUTTON_CLICKED, {
    client: apolloStreamClient,
  });

  const handleRecordClick = ({ videoURL }: GetStream_stream_streamRecords) => {
    if (videoURL) {
      setRecordVideoUrl(videoURL);
      handlePlayButtonClicked(`${stream?.id}`);
      rootRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleOnPlayNextClick = () => {
    if (recordsList?.length) {
      const currentVideoIndex = recordsList.findIndex(
        (record) => record.videoURL === videoPlayerUrl
      );

      if (currentVideoIndex < recordsList.length - 1) {
        const nextRecordVideoUrl = recordsList[currentVideoIndex + 1]
          .videoURL as string;
        setRecordVideoUrl(nextRecordVideoUrl);
      }
    }
  };

  const handlePlayButtonClicked = (streamId: string) => {
    try {
      playButtonClicked({
        variables: {
          streamId,
        },
      });
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
  };

  const handleVideoPlayerOnPlay = () => {
    const currentVideo = recordsList.find(
      (record) => record.videoURL === videoPlayerUrl
    );

    if (currentVideo) {
      handlePlayButtonClicked(currentVideo?.streamId);
    }
  };

  if (!recordsList?.length) {
    refetchStream();
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.videoContainer}>
        <VideoPlayer
          onPlay={handleVideoPlayerOnPlay}
          autoPlay={!!recordVideoUrl}
          src={videoPlayerUrl}
          volumeControl="speaker"
          {...(recordsList?.length > 0 && {
            onPlayNext: handleOnPlayNextClick,
          })}
        />
      </div>

      {recordsList.length > 0 && (
        <div className={styles.playlistContainer}>
          <div className={styles.playlistCardsContainer}>
            <h2 className={styles.playlistTitle}>Playlist</h2>
            {recordsList.map((item) => {
              const isActiveRecord =
                recordVideoUrl === item.videoURL ||
                videoPlayerUrl === item.videoURL;

              if (!item.videoURL) {
                return null;
              }

              return (
                <div
                  key={item.id}
                  className={cn(styles.card, {
                    [styles.active]: isActiveRecord,
                  })}
                  aria-label="stream preview card"
                >
                  <Image
                    src={item.videoThumbnailURL || ''}
                    alt="stream preview"
                    className={styles.cardVideo}
                    loading="lazy"
                  />
                  {item.duration && (
                    <span className={styles.duration}>
                      {computeDuration(item.duration)}
                    </span>
                  )}
                  {!isActiveRecord ? (
                    <button
                      className={styles.videoButton}
                      aria-label="play video"
                      onClick={() => handleRecordClick(item)}
                    >
                      <PlayVideoIcon className={styles.videoIcon} />
                    </button>
                  ) : (
                    <button
                      className={styles.videoButton}
                      aria-label="pause video"
                      onClick={() => handleRecordClick(item)}
                    >
                      <PauseVideoIcon
                        className={cn(styles.videoIcon, styles.pauseIcon)}
                      />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default StreamVideoPlayer;
