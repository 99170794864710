import { useRouter } from 'next/router';
// Hooks
import { useGetStoreAmas } from 'hooks';
// Helpers
import { getCheapestActiveAma } from 'helpers/ama';
import { trackAddToCartAma } from 'helpers/metrics';
import { getStreamerAvatar } from 'helpers/streams';
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Constants
import { ORDER_AMA } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Ui2
import Text from 'ui2/Text/Text';
import Button from 'ui2/Button/Button';

import styles from './PromoAma.module.scss';

type PromoAmaProps = {
  storeSlug: string;
};

const PromoAma = ({ storeSlug }: PromoAmaProps) => {
  const router = useRouter();

  const { data } = useGetStoreAmas({
    variables: {
      input: { slug: storeSlug },
    },
  });

  const cheapestActiveAma = getCheapestActiveAma(data?.store?.amas || []);
  const amaPrice = Number(cheapestActiveAma?.price || 0);
  const storeName = data?.store?.storeDetails?.storeName || '';

  const handleRequestAmaClick = () => {
    const amaId = cheapestActiveAma?.id || '';
    const amaType = cheapestActiveAma?.type || 'Ama';
    const storeSlug = data?.store?.slug || '';

    router.push(`${ORDER_AMA}/${storeSlug}`).then(() => {
      trackAddToCartAma({
        id: amaId,
        price: amaPrice,
        type: amaType,
        slug: storeSlug,
        storeName,
      });
    });
  };

  if (!cheapestActiveAma) {
    return null;
  }

  return (
    <section className={styles.section}>
      <Image
        className={styles.streamerAvatar}
        src={getStreamerAvatar(data?.store?.storeDetails)}
        alt={storeName}
        loading="lazy"
      />
      <h2 className={styles.title}>Ask me anything</h2>
      <Text color="light-white" textAlign="center" className={styles.text}>
        I’m here to answer your questions about training, about my journey, my
        predictions on the next big event, or anything you want to know. Hit me
        up and ask me anything!
      </Text>
      <Button onClick={handleRequestAmaClick} color="harvest-gold">
        Request Personal Video {formatCurrencyString(amaPrice)}
      </Button>
    </section>
  );
};

export default PromoAma;
