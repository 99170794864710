/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';
// Fragments
import {
  BlacklistFields,
  StreamChatFields,
  DirectMessagesFields,
} from 'api/chat/fragments';

export const ADD_STREAM_CHAT_V2 = gql`
  mutation AddStreamChatV2(
    $stream_id: ID!
    $chat_type: ChatType!
    $chat_message: String!
  ) {
    addStreamChatV2(
      stream_id: $stream_id
      chat_type: $chat_type
      chat_message: $chat_message
    ) {
      ...StreamChatFields
    }
  }
  ${StreamChatFields}
`;

export const UPDATE_STREAM_CHAT_TYPE = gql`
  mutation UpdateStreamChatType(
    $chat_id: ID!
    $stream_id: ID!
    $chat_type: ChatType!
  ) {
    updateStreamChatType(
      chat_id: $chat_id
      stream_id: $stream_id
      chat_type: $chat_type
    ) {
      ...StreamChatFields
    }
  }
  ${StreamChatFields}
`;

export const ADD_DIRECT_MESSAGE = gql`
  mutation AddDirectMessage(
    $stream_id: ID!
    $to_user_id: ID!
    $message_type: MessageType!
    $message: String!
  ) {
    addDirectMessage(
      stream_id: $stream_id
      to_user_id: $to_user_id
      message_type: $message_type
      message: $message
    ) {
      ...DirectMessagesFields
    }
  }
  ${DirectMessagesFields}
`;

export const ADD_USER_TO_BLACK_LIST = gql`
  mutation AddBlacklist(
    $user_id: ID!
    $stream_id: ID
    $block_type: BlockType!
  ) {
    addBlacklist(
      user_id: $user_id
      stream_id: $stream_id
      block_type: $block_type
    ) {
      ...BlacklistFields
    }
  }
  ${BlacklistFields}
`;

export const REMOVE_USER_FROM_BLACK_LIST = gql`
  mutation RemoveBlacklist(
    $user_id: ID!
    $stream_id: ID
    $block_type: BlockType!
  ) {
    removeBlacklist(
      user_id: $user_id
      stream_id: $stream_id
      block_type: $block_type
    ) {
      ...BlacklistFields
    }
  }
  ${BlacklistFields}
`;
