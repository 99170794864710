/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BlockType {
  Ban = "Ban",
  Kick = "Kick",
  Mute = "Mute",
}

export enum ChatType {
  Chat = "Chat",
  Exit = "Exit",
  Join = "Join",
  Promote = "Promote",
  Purchased = "Purchased",
  Tipped = "Tipped",
  Unsend = "Unsend",
}

export enum MessageType {
  DirectMessage = "DirectMessage",
  Prompt = "Prompt",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
