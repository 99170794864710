import { useQuery, QueryResult, QueryOptions } from '@apollo/client';
// Api
import { GET_STORE_AMAS } from 'api/ama/queries';
// Types
import {
  GetStoreAmas,
  GetStoreAmasVariables,
} from 'api/ama/types/GetStoreAmas';

type BasicQueryOptions = Omit<QueryOptions, 'query' | 'variables'>;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
  variables: GetStoreAmasVariables;
  skip?: boolean;
};

export const useGetStoreAmas = (
  options: UseGetAthleteMerchProductsOptions
): QueryResult<GetStoreAmas, GetStoreAmasVariables> => {
  const data = useQuery<GetStoreAmas, GetStoreAmasVariables>(GET_STORE_AMAS, {
    variables: {
      ...options.variables,
    },
    ...options?.options,
    skip: options?.skip,
  });

  return data;
};
