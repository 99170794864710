import { useRef } from 'react';
import cn from 'classnames';
// Types
import Slider, { Settings } from 'react-slick';
// Ui
import Icon from 'ui/Icon/Icon';
import Carousel from 'ui/Carousel/Carousel';
// Styles
import styles from './CarouselWithArrowsV2.module.scss';

const sliderSettings = {
  infinite: true,
  adaptiveHeight: false,
  speed: 500,
  swipeToSlide: true,
  variableWidth: true,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
} as Settings;

type CarouselWithArrowsV2Props = {
  settings?: Settings;
  theme?: Theme;
  hideArrows?: boolean;
};

const CarouselWithArrowsV2: React.FC<CarouselWithArrowsV2Props> = ({
  children,
  settings,
  hideArrows = false,
  theme = 'white',
}) => {
  const sliderRef = useRef<Slider | null>(null);

  const handleSlickNextClick = () => {
    sliderRef.current?.slickNext();
  };

  const handleSlickPrevClick = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <div
      className={cn(styles.root, {
        [styles[`theme-${theme}`]]: theme,
      })}
    >
      <div
        className={cn(styles.nav, {
          [styles.navHide]: hideArrows,
        })}
      >
        <button
          title="scroll back"
          className={styles.navButton}
          onClick={handleSlickPrevClick}
        >
          <Icon
            name="chevron-left"
            className={cn(styles.arrowIcon, styles.left)}
          />
        </button>
        <button
          title="scroll forward"
          className={styles.navButton}
          onClick={handleSlickNextClick}
        >
          <Icon
            name="chevron-right"
            className={cn(styles.arrowIcon, styles.right)}
          />
        </button>
      </div>

      <Carousel
        ref={sliderRef}
        {...sliderSettings}
        {...settings}
        className={styles.slider}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselWithArrowsV2;
