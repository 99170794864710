import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Types
import { GetMemorabilia_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabilia';
import { GetMemorabiliaMinimalInfo_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
import { MemorabiliaProductType } from 'api/graphql-global-types';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './MemorabiliaListItem.module.scss';

export type TypeListItem = 'default' | 'ad' | 'related';

type MemorabiliaListItemProps = {
  memorabilia:
    | GetMemorabilia_getMemorabilia_entities
    | GetMemorabiliaMinimalInfo_getMemorabilia_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  itemType: TypeListItem;
  className?: string;
  theme?: Theme;
};

const MemorabiliaListItem = ({
  memorabilia,
  openInNewTab,
  itemType,
  className,
  theme = 'white',
}: MemorabiliaListItemProps) => {
  const router = useRouter();

  const isDefault = itemType === 'default';
  const isAd = itemType === 'ad';
  const isRelated = itemType === 'related';

  const price = formatCurrencyString(memorabilia.price);
  const link = `/${memorabilia?.store?.slug}/memorabilia/${memorabilia.slug}`;
  const storeName = memorabilia?.store?.storeDetails?.storeName || '';

  const handleStoreButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const url = memorabilia.store?.slug
      ? `/${memorabilia.store?.slug}`
      : ATHLETES;

    router.push(url);
  };

  const mainImage = memorabilia?.images?.find((item) => item.isMainImage);

  const isMemorabilia = Boolean(
    memorabilia.memorabiliaProductType === MemorabiliaProductType.Memorabilia
  );

  return (
    <Link href={link || ''} prefetch={false}>
      <a
        className={cn(
          styles.root,
          {
            [styles.default]: isDefault,
            [styles.ad]: isAd,
            [styles.related]: isRelated,
            [styles[`theme-${theme}`]]: theme,
          },
          className
        )}
        title={`${memorabilia.title} by ${storeName}`}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <div className={cn(styles.imageWrapper, className)}>
          <div className={styles.memorabiliaBannerWrapper}>
            <div className={styles.memorabiliaBanner}>
              <p
                className={cn(styles.memorabiliaTitle, {
                  [styles.isMemorabilia]: isMemorabilia,
                })}
              >
                Memorabilia
              </p>
            </div>
            <div className={styles.numberAvailable}>
              <p
                className={styles.numberOfUnitsText}
              >{`${memorabilia?.numberOfUnits} available`}</p>
            </div>
          </div>

          <Image
            className={styles.productImage}
            src={mainImage?.url || ''}
            alt={memorabilia.title}
            priority
            sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
          />
        </div>

        <div className={styles.infoWrapper}>
          {isDefault && (
            <button
              className={styles.storeButton}
              onClick={handleStoreButtonClick}
            >
              {storeName}
            </button>
          )}
          <p className={cn(styles.title, { [styles.large]: !isAd })}>
            {memorabilia.title}
          </p>

          <div className={styles.infoFooter}>
            {isAd && (
              <>
                <div className={styles.infoFooterWrapper}>
                  <p className={cn(styles.price, { [styles.large]: !isAd })}>
                    {price}
                  </p>
                </div>
                <p className={styles.cta}>Buy Now</p>
              </>
            )}

            {!isAd && <p className={styles.viewMoreLabel}>view - {price}</p>}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default MemorabiliaListItem;
