import { useEffect } from 'react';

export const useHideHubSpotChat = () => {
  useEffect(() => {
    const style = document.createElement('style');
    style.innerText =
      'body #hubspot-messages-iframe-container { display: none!important; }';

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return null;
};
