/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const StreamChatFields = gql`
  fragment StreamChatFields on StreamChat {
    chat_id
    stream_id
    chat_type
    user_id
    user_firstname
    user_lastname
    chat_message
    date_created_iso
  }
`;

export const DirectMessagesFields = gql`
  fragment DirectMessagesFields on DirectMessage {
    message_id
    stream_id
    message_type
    from_user_id
    from_user_firstname
    from_user_lastname
    to_user_id
    message
    date_created_iso
  }
`;

export const BlacklistFields = gql`
  fragment BlacklistFields on Blacklist {
    user_id
    stream_id
    block_type
    date_created_iso
  }
`;
