import { gql } from '@apollo/client';
// Fragments
import { AmaFields } from 'api/ama/fragments';
import { StoreBasicFields } from 'api/store/fragments';
import { StreamBasicFields } from 'api/streams/fragments';
import { AmaRequestFields, AllAmaEntities } from './fragments';
import { ThankYouVideoFields } from 'api/thankYouVideo/fragments';

export const GET_AMA_REQUEST = gql`
  query AmaRequest($id: String!) {
    amaRequest(id: $id) {
      id
      amaVideoURL
      mediaJobStatus
      orderStatus
      ama {
        id
        type
      }
      store {
        ...StoreBasicFields
        nextStream {
          ...StreamBasicFields
          store {
            id
            slug
            storeDetails {
              id
              storeName
            }
          }
          price
          isFree
        }
      }
    }
  }
  ${StreamBasicFields}
  ${StoreBasicFields}
`;

export const GET_MY_INCOMING_AMA_REQUESTS = gql`
  query MyIncomingAmaRequests($input: AmaRequestListFilter!) {
    myIncomingAmaRequests(input: $input) {
      entities {
        ...AmaRequestFields
      }
      total
      offset
      limit
    }
  }
  ${AmaRequestFields}
`;

export const GET_MY_OUTCOMING_AMA_REQUESTS = gql`
  query MyOutcomingAmaRequests($input: AmaRequestListFilter!) {
    myOutcomingAmaRequests(input: $input) {
      entities {
        ...AmaRequestFields
      }
      total
      offset
      limit
    }
  }
  ${AmaRequestFields}
`;

export const GET_ALL_AMAS = gql`
  query GetAllAmas($input: AmaFilterInput, $withFilters: Boolean = false) {
    amas(input: $input) {
      entities {
        ...AllAmaEntities
      }
      total
      offset
      limit
      filters @include(if: $withFilters) {
        dateToResponse
        price
        sports {
          id
          name
        }
        hashtags {
          id
          name
          count
        }
      }
    }
  }
  ${AllAmaEntities}
`;

export const GET_MY_AMAS = gql`
  query GetMyAmas {
    myAmas {
      ...AmaFields
    }
  }
  ${AmaFields}
`;

export const GET_STORE_AMAS = gql`
  query GetStoreAmas($input: StoreGetInput!) {
    store(input: $input) {
      id
      slug
      firstName
      lastName
      role
      storeDetails {
        id
        avatarURL
        storeName
      }
      amas {
        id
        type
        price
        daysToResponse
        status
        videoURL
        videoThumbnailURL
      }
    }
  }
`;

export const GET_MY_AMA_SALES = gql`
  query GetAmaSales($input: MySalesInput) {
    getAmaSales(input: $input) {
      total
      offset
      limit
      entities {
        id
        customer {
          id
          lastName
          firstName
        }
        paidAt
        discountAmount
        requestedProfit
        ama {
          id
          type
        }
        thankYouVideo {
          ...ThankYouVideoFields
        }
      }
    }
  }
  ${ThankYouVideoFields}
`;
