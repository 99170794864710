import { useContext } from 'react';
// Context
import { StreamContext, StreamContextType } from 'context/StreamContext';

export const useStreamContext = (): StreamContextType => {
  const context = useContext(StreamContext);

  if (!context) {
    throw new Error(
      'useStreamContext must be used within an StreamContext Provider'
    );
  }

  return context;
};
