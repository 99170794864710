import { createContext, useCallback } from 'react';
import { useSetState } from 'react-use';
// Types
import { GetStream_stream } from 'api/streams/types/GetStream';
import { ChatMessage } from 'components/common/Stream/StreamChat/StreamChat';
import { ChatUser } from 'components/WatchStream/UnfinishedStream/components/ModeratorView/components/UsersInChat/UsersInChat';
// Hooks
import { useGetStreamBySlug } from 'hooks';

type StreamContextState = {
  managedChatMessage: ChatMessage | null;
  managedChatUserInfo: ChatUser | null;
  loadingGuest: boolean;
};

type StreamContextHandlers = {
  setManagedChatMessage: (managedChatMessage: ChatMessage) => void;
  clearManagedChatMessage: () => void;
  setManagedChatUserInfo: (managedChatUserInfo: ChatUser) => void;
  clearManagedChatUserInfo: () => void;
  startLoadingGuest: () => void;
  stopLoadingGuest: () => void;
};

export type StreamContextType = StreamContextHandlers &
  StreamContextState & {
    stream: GetStream_stream | null;
  };

const initialState: StreamContextState = {
  managedChatMessage: null,
  managedChatUserInfo: null,
  loadingGuest: false,
};

export const StreamContext = createContext<StreamContextType>(
  {} as StreamContextType
);

export const StreamProvider: React.FC = ({ children }) => {
  const [streamContext, setStreamContext] = useSetState<StreamContextState>(
    initialState
  );

  const { data: streamData } = useGetStreamBySlug({
    options: {
      fetchPolicy: 'cache-first',
    },
  });

  const stream = streamData?.stream || null;

  const setManagedChatMessage = useCallback(
    (managedChatMessage: ChatMessage) => {
      setStreamContext(() => ({
        managedChatMessage,
      }));
    },
    [setStreamContext]
  );

  const clearManagedChatMessage = useCallback(() => {
    setStreamContext(() => ({
      managedChatMessage: null,
    }));
  }, [setStreamContext]);

  const setManagedChatUserInfo = useCallback(
    (managedChatUserInfo: ChatUser | null) => {
      setStreamContext(() => ({
        managedChatUserInfo,
      }));
    },
    [setStreamContext]
  );

  const clearManagedChatUserInfo = useCallback(() => {
    setStreamContext(() => ({
      managedChatUserInfo: null,
    }));
  }, [setStreamContext]);

  const startLoadingGuest = useCallback(() => {
    setStreamContext(() => ({
      loadingGuest: true,
    }));
  }, [setStreamContext]);

  const stopLoadingGuest = useCallback(() => {
    setStreamContext(() => ({
      loadingGuest: false,
    }));
  }, [setStreamContext]);

  return (
    <StreamContext.Provider
      value={{
        ...streamContext,
        stream,
        setManagedChatMessage,
        clearManagedChatMessage,
        setManagedChatUserInfo,
        clearManagedChatUserInfo,
        startLoadingGuest,
        stopLoadingGuest,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};
