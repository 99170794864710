import { createUploadLink } from 'apollo-upload-client';
import {
  split,
  from,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
// Hoc
import { errorLink } from 'hocs/withApollo';
// Helpers
import { isTestEnv } from 'helpers/env';
import { getCohostOrRegularAccessTokenFromCookies } from 'helpers/cookies';

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_CHAT_API_HOST,
});

const config = {
  url: process.env.NEXT_PUBLIC_CHAT_WSS_HOST || '',
  region: 'us-east-1',
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    // a fallback with an empty token to make a public request
    jwtToken: () => getCohostOrRegularAccessTokenFromCookies() || `Bearer `,
  },
} as any;

const authLink = setContext((_, { headers }) => {
  const token = getCohostOrRegularAccessTokenFromCookies();

  return {
    headers: {
      ...headers,
      // a fallback with an empty token to make a public request
      Authorization: `Bearer ${token || ''}`,
    },
  };
});

const chatLink = from([
  errorLink,
  authLink,
  (httpLink as unknown) as ApolloLink,
]);

const chatWsLink = from([
  errorLink,
  createSubscriptionHandshakeLink(config, (httpLink as unknown) as ApolloLink),
]);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  chatWsLink,
  chatLink
);

/**
 * Add a check for test env and keep the "client" as "undefined"
 * as there is no clear way to mock an apollo client instance
 */
export default isTestEnv
  ? undefined
  : new ApolloClient({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
        },
      },
      cache: new InMemoryCache(),
      link,
    });
