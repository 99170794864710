import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
// Context
import { useStreamContext } from 'hooks';
// Hooks
import { useConfirmCardPayment } from 'hooks';
// Api
import apolloChatClient from 'api/apolloChatClient';
import { ADD_STREAM_CHAT_V2 } from 'api/chat/mutations';
import { CREATE_STREAM_TIP } from 'api/store/mutations';
// Types
import {
  CreateStreamTip,
  CreateStreamTipVariables,
} from 'api/store/types/CreateStreamTip';
import {
  AddStreamChatV2,
  AddStreamChatV2Variables,
} from 'api/chat/types/AddStreamChatV2';
import { ChatType } from 'api/graphql-chat-global-types';
import { StreamStatus, UserRole } from 'api/graphql-global-types';
// Helpers
import { getStreamerName } from 'helpers/streams';
// Ui2
import Button from 'ui2/Button/Button';
import TextField from 'ui2/TextField/TextField';
// Components
import { showToast } from 'components/common/Toast/Toast';
import ProductPayment from 'components/common2/ProductPayment/ProductPayment';
// Styles
import styles from './TipStore.module.scss';

type TipStoreProps = {
  onCloseModal: () => void;
};

type FormInputs = {
  amount: number;
  message: string;
};

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .min(0.01, 'Please enter an amount higher than $0.00')
    .transform((value) => (isNaN(value) ? undefined : Number(value)))
    .required('Tip Amount is required field.'),
  message: yup
    .string()
    .max(1000, "Maximum number of characters can't be more than 1000"),
});

const defaultErrorMessage =
  'Looks like something went wrong. Please double-check all fields and try again';

const TipStore = ({ onCloseModal }: TipStoreProps) => {
  const { stream } = useStreamContext();

  const [paymentMethodId, setPaymentMethodId] = useState<string>('');

  const [createStreamTip, { loading: createStreamTipLoading }] = useMutation<
    CreateStreamTip,
    CreateStreamTipVariables
  >(CREATE_STREAM_TIP);

  const {
    confirmCardPayment,
    loading: stripeLoading,
  } = useConfirmCardPayment();

  const showError = (message: string = defaultErrorMessage) => {
    showToast({
      message,
      type: 'error',
    });
  };

  const streamId = stream?.id || '';
  const storeName = getStreamerName(stream?.store);
  const isStreamEnded = stream?.streamStatus === StreamStatus.Ended;

  const [addStreamChatV2, { loading: sendChatMessageLoading }] = useMutation<
    AddStreamChatV2,
    AddStreamChatV2Variables
  >(ADD_STREAM_CHAT_V2, {
    client: apolloChatClient,
  });

  const {
    watch,
    reset,
    register,
    handleSubmit,
    errors: formErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      amount: undefined,
    },
  });
  const amount = watch('amount');
  const message = watch('message');

  const handleStreamChatMessageSend = async (): Promise<void> => {
    try {
      await addStreamChatV2({
        variables: {
          stream_id: streamId,
          chat_type: ChatType.Tipped,
          chat_message: amount.toString(),
        },
      });

      if (!message) {
        showToast({
          type: 'success',
          message: `You've successfully tipped ${storeName}`,
        });
      }

      if (message) {
        await addStreamChatV2({
          variables: {
            stream_id: streamId,
            chat_type: ChatType.Chat,
            chat_message: message,
          },
        });

        showToast({
          type: 'success',
          message: `You've successfully sent a chat message and tipped ${storeName}`,
        });
      }

      onCloseModal();
      reset();
    } catch (err) {
      showError(err?.message);
    }
  };

  const handlePaymentSuccess = async () => {
    if (isStreamEnded) {
      showToast({
        type: 'success',
        message: `You've successfully tipped ${storeName}`,
      });
      onCloseModal();
      reset();
    } else {
      await handleStreamChatMessageSend();
    }
  };

  const handlePayment: SubmitHandler<FormInputs> = async (): Promise<void> => {
    try {
      if (paymentMethodId) {
        const { data } = await createStreamTip({
          variables: {
            input: {
              message,
              streamId,
              amount: +amount,
            },
          },
        });

        const stripeIntentClientSecret =
          data?.createStreamTip?.stripeIntentClientSecret;

        if (stripeIntentClientSecret) {
          await confirmCardPayment({
            paymentMethodId,
            stripeIntentClientSecret,
            onErrorCallback: showError,
            onSuccessCallback: handlePaymentSuccess,
          });
        } else {
          showError();
        }
      }
    } catch (err) {
      showError();
    }
  };

  const isCtaLoading =
    createStreamTipLoading || sendChatMessageLoading || stripeLoading;

  const role =
    stream?.store?.role === UserRole.ContentCreator
      ? 'Content Creator'
      : stream?.store?.role;

  if (!stream?.store) {
    return null;
  }

  return (
    <form aria-label={`Tip to ${storeName}`}>
      <p className={styles.header}>
        {`Send a tip to ${storeName} to show your appreciation!`}
      </p>

      <div className={styles.body}>
        <div className={styles.bodySection}>
          <TextField
            className={styles.amountInput}
            name="amount"
            ref={register}
            placeholder="$0.00"
            error={formErrors?.amount?.message}
            label="Tip Amount"
            theme="black"
            type="number"
          />
          {!isStreamEnded && (
            <TextField
              name="message"
              ref={register}
              error={formErrors?.message?.message}
              theme="black"
              label={`Message to ${role}`}
              type="textarea"
            />
          )}
        </div>

        <div className={styles.paymentSection}>
          <ProductPayment onSelect={setPaymentMethodId} theme="black" />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          onClick={handleSubmit(handlePayment)}
          loading={isCtaLoading}
          disabled={!paymentMethodId}
          type="submit"
          color="harvest-gold"
        >
          Send Tip
        </Button>
      </div>
    </form>
  );
};

export default TipStore;
