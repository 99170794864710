/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const DestinationFields = gql`
  fragment DestinationFields on Destination {
    destinationId
    type
    name
    isValid
    thumbnail
  }
`;

export const StreamDestinationFields = gql`
  fragment StreamDestinationFields on StreamDestination {
    destinationId
    converterId
    status
    type
    name
    isEnabled
  }
`;

export const HostFields = gql`
  fragment HostFields on Host {
    id
    uid
    name
    email
    status
    acceptanceStatus
    deletedAt
    acceptedAt
    enteredAt
  }
`;

export const HostPublicFields = gql`
  fragment HostPublicFields on Host {
    id
    uid
    name
    status
    acceptanceStatus
    deletedAt
    acceptedAt
    enteredAt
  }
`;
