import React, { useEffect } from 'react';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { useRouter } from 'next/router';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
// Context
import { StreamProvider } from 'context/StreamContext';
// Hocks
import withApollo from 'hocs/withApollo';
// Types
import { StreamStatus, UserRole } from 'api/graphql-global-types';
// Hooks
import { useGetCurrUser, useGetStreamBySlug, useHideHubSpotChat } from 'hooks';
// Helpers
import { getTokenFromCookies } from 'helpers/cookies';
import { getStreamPath } from 'helpers/routes';
// Layouts
import Head from 'layouts/Head/Head';
import Header from 'layouts/Header/Header';
import Main from 'layouts/Main/Main';
import Footer from 'layouts/Footer/Footer';
import StripeElements from 'layouts/StripeElements/StripeElements';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';
// Components
import FinishedStream from 'components/WatchStream/FinishedStream/FinishedStream';

const UnfinishedStream = dynamic(
  import('components/WatchStream/UnfinishedStream/UnfinishedStream'),
  { ssr: false }
);

const WatchStream: NextPage = () => {
  useHideHubSpotChat();

  const { push } = useRouter();
  const { data: userData } = useGetCurrUser();
  const { data, refetch, updateQuery, loading } = useGetStreamBySlug({
    options: {
      fetchPolicy: 'network-only',
    },
  });
  const token = getTokenFromCookies();

  const stream = data?.stream;
  const isLoading = !data || loading;
  const isStreamEndedOrCancelled =
    stream?.streamStatus === StreamStatus.Ended ||
    stream?.streamStatus === StreamStatus.Cancelled;

  const isUserAStreamer = userData?.me?.id === stream?.store?.id;
  const isUserAModerator = userData?.me?.role === UserRole.Moderator;
  const isUserACustomer = userData && !isUserAStreamer && !isUserAModerator;

  const isWatchStreamNotAvailable = token?.accessToken
    ? stream && isUserACustomer && !stream?.isPurchased && !stream?.isFree
    : stream &&
      (!stream?.isFree ||
        (stream?.isFree && stream?.streamStatus === StreamStatus.Scheduled));

  useEffect(() => {
    if (isWatchStreamNotAvailable) {
      const redirectPath = getStreamPath(stream, 'streams');

      push(redirectPath);
    }
  }, [stream, userData, isWatchStreamNotAvailable, push]);

  if (isWatchStreamNotAvailable) return null;

  return (
    <>
      <Head
        title={`${data ? `${stream?.name}` : 'Stream loading...'}`}
        description={`${stream?.description || ''}`}
        image={stream?.imageURL || ''}
      />
      <Header theme={isStreamEndedOrCancelled ? 'white' : 'black'} />

      {isLoading ? (
        <Main withoutBottomPadding>
          <FourDotsLoader />
        </Main>
      ) : (
        <StripeElements>
          <StreamProvider>
            {isStreamEndedOrCancelled ? (
              <FinishedStream stream={stream} refetchStream={refetch} />
            ) : (
              <>
                {stream && (
                  <UnfinishedStream
                    stream={stream}
                    refetchStream={refetch}
                    updateStreamQuery={updateQuery}
                  />
                )}
              </>
            )}
          </StreamProvider>
        </StripeElements>
      )}

      {isStreamEndedOrCancelled && <Footer />}
    </>
  );
};

export default withApollo(WatchStream, { getDataFromTree });
