import cn from 'classnames';
import React from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
// UI
import Icon from 'ui/Icon/Icon';
// UI2
import Button, { ButtonProps } from 'ui2/Button/Button';
// Styles
import 'react-responsive-modal/styles.css';
import styles from './RedesignedModal.module.scss';

type DefaultTypeProps = {
  type?: 'default';
  title: string;
  description?: string;
  children?: React.ReactNode;
};

type ConfirmTypeProps = {
  type: 'confirm';
  title: string;
  description: string;
  children?: never;
};

type ModalType = DefaultTypeProps | ConfirmTypeProps;

type RedesignedModalProps = {
  open: boolean;
  onCancel: () => void;
  /**
   * "cancelText" is a flag to show "Cancel" button
   */
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  /**
   * "onOk" is a flag to show "Ok" button
   */
  theme?: Theme;
  onOk?: () => void;
  okText?: string;
  okButtonProps?: ButtonProps;
  focusTrapped?: boolean;
  mobileBreak?: boolean;
  className?: string;
  onAnimationEnd?: () => void;
} & ModalType;

const RedesignedModal: React.FC<RedesignedModalProps> = ({
  open,
  title,
  description,
  type = 'default',
  onCancel,
  cancelText,
  cancelButtonProps,
  onOk,
  okText,
  okButtonProps,
  theme = 'white',
  focusTrapped = true,
  mobileBreak = true,
  className,
  onAnimationEnd,
  children,
}) => {
  const withCancelButton = !!cancelText;
  const withOkButton = !!onOk;
  const withActions = withCancelButton || withOkButton;
  const isConfirmType = type === 'confirm';

  return (
    <ResponsiveModal
      center
      open={open}
      classNames={{
        overlay: styles.overlay,
        modal: cn(
          styles.modal,
          {
            [styles.withActions]: withActions,
            [styles[`theme-${theme}`]]: !!theme,
            [styles[`type-${type}`]]: !!type,
          },
          className
        ),
      }}
      onClose={onCancel}
      focusTrapped={focusTrapped}
      showCloseIcon={false}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={styles.entry}>
        <div className={styles.header}>
          <h4 className={styles.title}>{title}</h4>

          {description && !isConfirmType && (
            <p className={styles.description}>{description}</p>
          )}

          <button
            className={styles.closeButton}
            onClick={onCancel}
            aria-label="Close"
          >
            <Icon name="close-new" className={styles.closeIcon} />
          </button>
        </div>

        {mobileBreak && (
          <span className={styles.mobileBreak} data-testid="mobile-break" />
        )}

        <div className={styles.body}>
          {isConfirmType ? (
            <p className={styles.description}>{description}</p>
          ) : (
            children
          )}
        </div>
      </div>

      {withActions && (
        <div className={styles.actions}>
          {withCancelButton && (
            <Button
              color="transparent"
              onClick={onCancel}
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
          )}

          {withOkButton && (
            <Button color="harvest-gold" onClick={onOk} {...okButtonProps}>
              {okText || 'Ok'}
            </Button>
          )}
        </div>
      )}
    </ResponsiveModal>
  );
};

export default RedesignedModal;
