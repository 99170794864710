import { createUploadLink } from 'apollo-upload-client';
import {
  split,
  from,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
// Hoc
import { errorLink } from 'hocs/withApollo';
// Helpers
import {
  getTokenFromCookies,
  getCohostTokenFromCookies,
  getCohostOrRegularAccessTokenFromCookies,
} from 'helpers/cookies';
import { isTestEnv } from 'helpers/env';

export type StreamRole = 'streamer' | 'cohost' | 'watcher';

export const COHOST_ROLE_CONTEXT: { context: { role: StreamRole } } = {
  context: { role: 'cohost' },
};

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_STREAM_API_HOST,
});

const config = {
  url: process.env.NEXT_PUBLIC_STREAM_WSS_HOST,
  region: 'us-east-1',
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    // a fallback with an empty token to make a public request
    jwtToken: () => getCohostOrRegularAccessTokenFromCookies() || `Bearer `,
  },
} as any;

const authLink = setContext(
  (_, { headers, role }: { headers: any; role: StreamRole }) => {
    const isCohost = role === 'cohost';
    // get a cohost token for a related requests only
    const token = isCohost
      ? getCohostTokenFromCookies()
      : // get a regular token with a fallback to a cohost token if a context with a role value was missed or token isn't defined
        getTokenFromCookies()?.accessToken || getCohostTokenFromCookies();

    return {
      headers: {
        ...headers,
        // a fallback with an empty token to make a public request
        Authorization: `Bearer ${token || ''}`,
      },
    };
  }
);

const streamLink = from([
  errorLink,
  authLink,
  (httpLink as unknown) as ApolloLink,
]);

const streamWsLink = from([
  errorLink,
  createSubscriptionHandshakeLink(config, (httpLink as unknown) as ApolloLink),
]);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  streamWsLink,
  streamLink
);

/**
 * Add a check for test env and keep the "client" as "undefined"
 * as there is no clear way to mock an apollo client instance
 */
export default isTestEnv
  ? undefined
  : new ApolloClient({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
        },
      },
      cache: new InMemoryCache(),
      link,
    });
