// Types
import { AmaStatus } from '../api/graphql-global-types';

export function getCheapestActiveAma<T>(amas: T[]): T {
  const cheapestActiveAma = amas
    ?.filter((ama: any) => ama?.status === AmaStatus.Active)
    ?.sort(
      (a: any, b: any) => Number(a?.price || 0) - Number(b?.price || 0)
    )?.[0];

  return cheapestActiveAma;
}
