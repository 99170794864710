import cn from 'classnames';
import Link from 'next/link';
// Types
import { GetAffiliateStreamProducts_getAffiliateStreamProducts } from 'api/streams/types/GetAffiliateStreamProducts';
// Helpers
import { formatCurrencyStringWithoutName } from 'helpers/formatCurrencyString';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './AffiliateProductItem.module.scss';

type AffiliateProductItemProps = {
  affiliateProduct: GetAffiliateStreamProducts_getAffiliateStreamProducts;
  theme?: Theme;
};

const AffiliateProductItem = ({
  affiliateProduct,
  theme = 'white',
}: AffiliateProductItemProps) => {
  const { price, url, title, imageUrl } = affiliateProduct;

  const formattedPrice = formatCurrencyStringWithoutName(price);

  return (
    <Link href={url || ''} prefetch={false}>
      <a
        className={cn(styles.root, {
          [styles[`theme-${theme}`]]: theme,
        })}
        title={`${title}`}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <div className={cn(styles.imageWrapper)}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>{formattedPrice}</div>
          </div>

          <Image
            src={imageUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            sizes="25vw"
          />
        </div>

        <div className={styles.title}>{title}</div>
      </a>
    </Link>
  );
};

export default AffiliateProductItem;
