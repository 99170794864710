// Types
import { GetStream_stream } from 'api/streams/types/GetStream';
// Layouts
import Main from 'layouts/Main/Main';
// Components
import StreamsPlaylist from './components/StreamsPlaylist/StreamsPlaylist';
import PromoAma from './components/PromoAma/PromoAma';
// Styles
import styles from './FinishedStream.module.scss';

type FinishedStreamProps = {
  stream: GetStream_stream;
  refetchStream: () => void;
};

const FinishedStream = ({ stream, refetchStream }: FinishedStreamProps) => {
  return (
    <Main
      backgroundColor="white"
      className={styles.root}
      withoutBottomPadding
      withoutSidesPadding
    >
      <StreamsPlaylist stream={stream} refetchStream={refetchStream} />
      <PromoAma storeSlug={stream?.store?.slug || ''} />
    </Main>
  );
};

export default FinishedStream;
